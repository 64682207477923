import { mapGetters, mapMutations } from "vuex";
import byteSize from 'byte-size';

const generalMixin = {
  data() {
    return {
      cardHeader: "#dbd7d779",
      ant: "#273142"
    };
  },
  computed: {
    ...mapGetters(['getSession']),
  },
  methods: {
    ...mapMutations(["setChatStatus"]),
    passChat(value) {
      const data = {
        status: false,
        room: value
      };
      this.setChatStatus(data);
    },
    trimFirtTwo (val) {
			const trimmed = val.match(/.{1,2}/g)[0];
			return trimmed.toUpperCase();
		},
    formatByte(val) {
      const result = byteSize(val);
      return `${result.value}${result.unit}`
    },
    notifRouterPass(id) {
      if (this.getSession.user_type === 1) {
        this.$router.push(`/dashboard/order/${id}`)
      } else {
        this.$router.push(`/orderDetail/${id}/bid`)
      }
    },
    roundToTwo(num) {
        return +(Math.round(num + "e+2")  + "e-2");
    },
    formatAmount(amount) {
      const result = parseFloat(amount);
      return this.roundToTwo(result);
    }
  },
};

export default generalMixin;
