import { mapGetters, mapActions } from "vuex";

const validationMxn = {
  data() {
    return {
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      passwordRules: [v => !!v || "password is required"],
      required: [v => !!v || "Field is required"],
      digits: [
        v => !!v || "Field is required",
        v => /^\d+$/.test(v) || "Only digits are required"
      ],
      amountRule: [
        v => !!v || "Amount is required",
        v => /^[+-]?\d+(\.\d+)?$/.test(v) || "Only digits are required",
        v => v > 0 || "Amoun: Should be greator that & 0.01",
      ],
      error: {}
    };
  },
  computed: {
    ...mapGetters(["getErrors", 'getOrderDetail'])
  },
  watch: {
    getErrors(val) {
      this.error = val;
    }
  },
  methods: {
    ...mapActions(['performPostActions', 'performGetActions','performUpdateActions', 'uploadImage', 'performDeleteActions', 'performPutActions']),
    clear(field) {
      this.error[field] = "";
    }

  }
};

export default validationMxn;
