<template>
  <v-card :color="ant">
    <v-card-title :color="cardHeader">
      <h3 class="mx-auto">Tutor Registration</h3>
    </v-card-title>
    <v-divider class="mt-n1"></v-divider>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="signup">
      <v-card-text>
        {{ response }}
        <v-text-field
          v-model="email"
          label="Email"
          :loading="loading"
          :rules="emailRules"
          required
          :error-messages="error.email"
          @input="clear('email')"
        ></v-text-field>
        <v-text-field
          v-model="username"
          label="Username"
          :loading="loading"
          :rules="required"
          required
          :error-messages="error.username"
          @input="clear('username')"
        ></v-text-field>
        <v-text-field
          v-model="password"
          label="Password"
          :loading="loading"
          :rules="passwordRules"
          required
          :type="show ? 'text' : 'password'"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show = !show"
        ></v-text-field>
      </v-card-text>

      <v-card-actions no-gutters>
        <v-btn
          :disabled="!valid"
          shaped
          color="success"
          type="submit"
          :loading="loading"
          block
        >
          Create Account
        </v-btn>
      </v-card-actions>

      <v-card-actions class="text-center">
        <v-col class="text--disabled" style="cursor:pointer;">
          <div>
            <span>Customer account? <router-link to="/auth/register" class="text-decoration-underline ">Customer Registration</router-link></span>
          </div>
          <div class="mt-5">
            <span>Already Registered?
              <router-link to="/auth/login" class="text-decoration-underline ">
                Login
              </router-link>
            </span>
          </div>
        </v-col>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";
import generalMxn from "@/mixins/general_mixin";
import validationMxn from "@/mixins/validation_mixin";
import { mapActions } from "vuex";

export default {
  name: "SignUp",
  mixins: [generalMxn, validationMxn],
  data() {
    return {
      show: false,
      valid: false,
      username: "",
      password: "",
      email: "",
      response: "",
      loading: false,
      countryCode: null,
      country: null,
      timezone: null,
    };
  },
  mounted() {
    this.fetchCountry();
  },
  methods: {
    ...mapActions(["performPostActions"]),
    clear(field) {
      this.error[field] = "";
    },
    async signup() {
      const payload = {
        username: this.username,
        email: this.email,
        password: this.password,
        user_type: 2,
        country: this.country,
        country_code: this.countryCode,
        timezone: this.timezone,
      };

      const fullPayload = {
        params: payload,
        endpoint: "/register"
      };

      const response = await this.performPostActions(fullPayload);
      if (response.status === 200) {
        this.$router.push("/auth/login");
      }
    },
    fetchCountry() {
      const EXTREME_IP_KEY = process.env.VUE_EXTREME_IP_KEY;
      axios(`https://extreme-ip-lookup.com/json/?key=${EXTREME_IP_KEY}`)
        .then((response) => {
          this.country = response.data.country;
          this.countryCode = response.data.countryCode;
          this.timezone = response.data.timezone;
        })
        .catch(error => error);
    } 
  }
};
</script>

<style scoped>
.ant {
  background: #273142;
}
.card-header {
  height: 70px;
  border-bottom: 2px solid #dbd7d779;
}
</style>
